<template>
  <EntityList
    :config="config"
    :columns="columns"
    :formatted-columns="formattedColumns"
    :filters="filters"
    :filter-vals.sync="filterVals"
    single-edit
    authority="General_Lead"
    enable-disable-toggle
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'

import storeModule from '../leadsStoreModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      filterVals: [],
      leadSourceOptions: [],
      leadCategoryOptions: [],
      companyOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'lead',
        endpoint: 'lead',
        route: 'leads',
        title: {
          single: this.$i18n.t('Lead'),
          plural: this.$i18n.t('Leads'),
        },
        limited: this.$store.getters['app-common/maxLeads'],
      }
    },

    columns() {
      return [
        { key: 'id', label: '#', sortable: true },
        { key: 'participant.firstName', label: this.$i18n.t('First Name'), sortable: true },
        { key: 'participant.lastName', label: this.$i18n.t('Last Name'), sortable: true },
        { key: 'leadSource.title', label: this.$i18n.t('Lead Source'), sortable: true },
        { key: 'enabled', label: this.$i18n.t('enabled') },
        { key: 'actions', label: this.$i18n.t('actions') },
      ]
    },

    formattedColumns() {
      return [{ key: 'enabled', type: 'boolean' }]
    },

    filters() {
      return [
        {
          name: 'company',
          label: this.$t('Company'),
          options: this.companyOptions,
        },
        {
          name: 'leadsource',
          label: this.$t('Lead Source'),
          options: this.leadSourceOptions,
        },
        {
          name: 'leadcategory',
          label: this.$t('Lead Category'),
          options: this.leadCategoryOptions,
        },
        {
          name: 'enabled',
          type: 'boolean',
          label: this.$i18n.t('enabled'),
        },
        {
          name: 'createdfrom',
          type: 'date',
          label: this.$i18n.t('Created From'),
          value: null,
          maxDateFieldIdx: '3',
          maxDate: new Date(),
        },
        {
          name: 'createdto',
          type: 'date',
          label: this.$i18n.t('Created To'),
          value: null,
          minDateFieldIdx: '2',
          maxDate: new Date(),
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchLeadSources').then(response => {
      this.leadSourceOptions = response.data
    })
    this.$store.dispatch('app-common/fetchLeadCategories').then(response => {
      this.leadCategoryOptions = response.data
    })
    this.$store.dispatch('app-common/fetchCompanies').then(response => {
      this.companyOptions = response.data
    })
  },
}
</script>
